<template>
  <div>
    <v-list nav class="rounded-lg">
      <v-list-item
          class="cursor-pointer"
          @click="$router.push('/products')"
          :class="$route.name === 'products' ? 'v-list-item--active primary white--text' :'' ">
        <v-list-item-avatar size="22">
          <v-icon size="20">mdi-shopping-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Liste produits</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/products/categories"
                   active-class="primary white--text">
        <v-list-item-avatar size="22" tile>
          <v-icon size="20">mdi-arrange-send-backward</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Liste catégories</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
          to="/products/brands"
          active-class="primary white--text">
        <v-list-item-avatar size="22" tile>
          <v-icon size="20">mdi-arrange-send-backward</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Liste marques</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>